<template>
  <router-view name="cookies" />
  <router-view name="header" />

  <div class="b2blayout_content">
    <router-view />
  </div>

  <router-view name="footer" />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "B2bLayout",
});
</script>

<style scoped>
.b2blayout_content {
  position: relative;
  background-color: #f6f7fb;
}
</style>